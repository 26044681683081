/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import PropTypes from "prop-types"
import Image from "gatsby-image"

export const HowItWorksListing = ({ list, ...props }) => {
  return (
    <ul
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
        display: "grid",
        gridTemplateColumns: ["1fr"],
        gridGap: [3, 5],
      }}
      {...props}
    >
      {list.map(({ title, description, image }, index) => (
        <li key={title} sx={{ textAlign: "center", position: "relative" }}>
          <div
            sx={{
              position: "absolute",
              width: "48px",
              height: "48px",
              left: "50%",
              transform: "translateX(-50%) translateY(-20px)",
              zIndex: 10,
              borderRadius: "50%",
              bg: "secondary",
              color: "background",
              fontWeight: "heading",
              lineHeight: "48px",
              textAlign: "center",
              fontSize: 3,
            }}
          >
            {index + 1}
          </div>
          <Image
            sx={{
              height: 200,
              width: 200,
              borderRadius: "50%",
              mx: "auto",
              border: theme => `6px solid ${theme.colors.gray3}`,
              mb: 4,
            }}
            fluid={image.fluid}
          />
          <div>
            <Heading>{title}</Heading>
            <p sx={{ maxWidth: 500, mx: "auto", color: "gray4" }}>
              {description.description}
            </p>
          </div>
        </li>
      ))}
    </ul>
  )
}

HowItWorksListing.defaultProps = {
  list: [],
}

HowItWorksListing.propTypes = {
  list: PropTypes.array,
}

export default HowItWorksListing
