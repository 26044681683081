/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"
import { graphql } from "gatsby"
import { Layout, SEO, QuoteBox } from "../components"
import GetCashOfferForm from "../forms/get-cash-offer"
import HowItWorksListing from "../views/how-it-works-listing"

const HowItWorks = ({ data }) => {
  const {
    title,
    headingTitle,
    headingSubtitle,
    listing,
    quoteBox,
  } = data.contentfulHowItWorksTemplate

  return (
    <Layout>
      <SEO title={title} />
      <Container sx={{ px: 3, py: [4, 5, 100] }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", null, "2fr 1fr"],
            gridGap: [4, 5],
            mb: [4, 5, 100],
          }}
        >
          <div sx={{ textAlign: "center" }}>
            <Heading as="h1" sx={{ fontSize: 5, mb: 3 }}>
              {headingTitle}
            </Heading>
            <div sx={{ fontSize: 3, mb: [4, 5] }}>{headingSubtitle}</div>
            <HowItWorksListing list={listing} />
          </div>
          <div>
            <GetCashOfferForm />
          </div>
        </div>
        <QuoteBox richText={quoteBox} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulHowItWorksTemplate(title: { eq: "How It Works" }) {
      id
      title
      headingTitle
      headingSubtitle
      listing {
        title
        description {
          description
        }
        image {
          ...CustomContentfulFluid
        }
      }
      quoteBox {
        raw
      }
    }
  }
`

export default HowItWorks